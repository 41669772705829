<template>
  <v-col cols="12">
    <v-card class="pa-2 main-card" outlined>
      <h2>404</h2>

      <p>Stranica ne postoji.</p>
    </v-card>
  </v-col>
</template>

<script>
export default {
  name: "Error404",
};
</script>
